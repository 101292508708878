import { IHttpClient } from '@wix/yoshi-flow-editor';
import { queryAvailability } from '@wix/ambassador-bookings-availability-v1-slot-availability/http';
import { QueryAvailabilityRequest } from '@wix/ambassador-bookings-availability-v1-slot-availability/types';
import { components } from './components';
import { DateTimeFormatter } from '@wix/bookings-date-time';
import { PriceUtils } from '@wix/bookings-uou-mappers';
import { DEFAULT_LOCALE } from '../../utils/constants';
import { biReportWidgetLoadedEmpty } from '../../biEvents';
import {
  ServicesCatalogServer,
  GetBusinessRequest,
} from '@wix/ambassador-services-catalog-server/http';
import { sdk as imageSDK } from '@wix/image-kit';

const CATALOG_READER_BASE_URL = '/_api/services-catalog';

export const getSlotsAvailability = async (
  httpClient: IHttpClient,
  businessTimezoneData,
  props,
  numberOfResources: number = 1,
  isMobile: boolean = false,
) => {
  const filterBuilder = () => {
    // console.log('UOU | Calculating availability for Mobile:', isMobile);
    // console.log('UOU | locale', locale);
    const dateFrom = new Date(new Date().setHours(0, 0, 0, 0))
    // console.log('UOU | dateFrom', dateFrom);
    const filterObject = {
      serviceId: [props.serviceId],
      startDate: buildDateFromString(),
      endDate: buildDateToString(),
      bookable: true,
    };
    // console.log('UOU | filterObject:', filterObject);

    if (!props.locationId.includes('CUSTOM')) {
      // Relevant for CUSTOM and CUSTOMER locations
      // console.log('UOU | Business location found');
      if (!props.locationId.includes('BUSINESS')) {
        filterObject['location.businessLocation.id'] = [props.locationId];
      } else {
        // console.log('UOU | ... but not configured');
      }
    } else {
      // console.log('UOU | Custom location found');
    }
    return filterObject;
  };

  const buildDateFromString = () => {
    const now = new Date()
    return `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}T00:00:00`
  }

  const buildDateToString = () => {
    const now = new Date(new Date().setMonth(new Date().getMonth() + 6))
    return `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}T23:59:59`
  }

  const queryAvailabilityRequest: QueryAvailabilityRequest = {
    query: {
      filter: filterBuilder(),
    },
    slotsPerDay: isMobile
      ? props.mobileNumberOfSlots * numberOfResources
      : props.numberOfSlots * numberOfResources,
    timezone:
      businessTimezoneData.userTimezonePreference === 'business'
        ? businessTimezoneData.businessTimezone
        : businessTimezoneData.clientTimezone,
  };
  // console.log('UOU | queryAvailabilityRequest:', queryAvailabilityRequest);
  const availability = await httpClient.request(
    queryAvailability(queryAvailabilityRequest),
  );
  // console.log('Returning availability:', availability.data);
  return availability.data;
};

export const updateWidgetInfo = async (
  $w,
  flowAPI,
  availability,
  businessTimezoneData,
  locale,
  props,
  timezone,
  service,
  biLogger,
) => {
  const mainRepeaterData = prepareRepeaterData(
    availability,
    locale,
    flowAPI.environment.isMobile
      ? props.mobileNumberOfSlots
      : props.numberOfSlots,
    timezone,
  );
  // console.log('UOU | updateWidgetData mainRepeaterData:', mainRepeaterData);
  // if (!env.isSSR) {
  //   clientTimezone =
  //     navigator.languages && navigator.languages.length
  //       ? navigator.languages[0]
  //       : navigator.language;
  // }
  // console.log('UOU | clientTimezone:', clientTimezone);

  populateServiceData(
    $w,
    flowAPI,
    props,
    service,
    timezone,
    locale,
    businessTimezoneData,
  );

  populateWidgetData(
    $w,
    flowAPI,
    service,
    mainRepeaterData,
    locale,
    timezone,
    biLogger,
  );
};

export const prepareRepeaterData = (
  availability,
  locale,
  numberOfSlots,
  preferredTimezone,
) => {
  const availabilityEntries = availability.availabilityEntries;
  const repeaterArray: any[] = [];
  let arrayOfKeys: string[] = [];
  let slotDate: string;
  const mapOfSlots = new Map();
  if (availabilityEntries.length !== 0) {
    for (const availabilityEntry of availabilityEntries) {
      const slot = availabilityEntry.slot;
      if (arrayOfKeys.length <= numberOfSlots) {
        if (arrayOfKeys.includes(slot.startDate)) {
          mapOfSlots.get(slot.startDate).push(slot);
          arrayOfKeys = Array.from(mapOfSlots.keys());
        } else if (arrayOfKeys.length !== numberOfSlots) {
          mapOfSlots.set(slot.startDate, [slot]);
          arrayOfKeys = Array.from(mapOfSlots.keys());
        } else {
          break;
        }
      } else {
        break;
      }
    }
  } else {
    // console.log('UOU | No slots to display');
  }
  // console.log('UOU | mapOfSlots before return:', mapOfSlots);
  // console.log('UOU | locale:', locale);
  mapOfSlots.forEach((value, key) => {
    const dateAndTimeFormatter = new DateTimeFormatter(
      locale,
      preferredTimezone,
    );
    // console.log('dateAndTimeFormatter:', dateAndTimeFormatter);
    slotDate = dateAndTimeFormatter.formatDate(key);
    // console.log('UOU | slotDate:', slotDate);
    // console.log('UOU | repeaterArray:', repeaterArray);
    const selectedObject = repeaterArray.find((element) =>
      element.date.toString().includes(slotDate),
    );
    // console.log('UOU | selectedObject:', selectedObject);
    const objectIndex = repeaterArray.indexOf(selectedObject);
    // console.log('UOU | Index:', objectIndex);

    if (
      selectedObject !== undefined &&
      repeaterArray.indexOf(selectedObject) !== -1
    ) {
      value.forEach((slot) => {
        repeaterArray[objectIndex].slots.push(slot);
      });
    } else {
      repeaterArray.push({
        _id: repeaterArray.length.toString(),
        date: slotDate,
        slots: value,
      });
    }
  });
  // console.log('UOU | repeaterArray:', repeaterArray);
  return repeaterArray;
};

export const populateServiceData = (
  $w,
  flowAPI,
  props,
  service,
  timezone,
  locale,
  businessTimezoneData,
) => {
  const t = flowAPI.translations.t;
  // console.log('UOU | populateServiceData with:', service);
  if (service?.media?.mainMedia === undefined) {
    // console.log('UOU | No image in service, removing image');
    $w(components.serviceImage).collapse();
    $w(components.imageC).collapse();
  } else {
    // console.log('UOU | Updating image in service');
    const { image } = service.media.mainMedia
    const imageLink = (image.width === 0 || image.height === 0) ? (image.url.includes('media') ? `https://static.wixstatic.com/${image.url}` : `https://static.wixstatic.com/media/${service.media.mainMedia.image.url}`) : getImageUrl(image)    
    $w(components.serviceImage).setImage(imageLink)
    $w(components.serviceImage).expand();
    $w(components.imageC).expand();
  }

  service?.conferencing?.enabled
    ? $w(components.vcBadge).expand()
    : $w(components.vcBadge).collapse();
  $w(components.serviceName).text = service.name;

  if (!flowAPI.environment.isSSR) {
    $w(
      components.serviceName,
    ).html = `<h1> <a href="${service.urls.servicePage.url.replace(
      '-statics',
      '',
    )}" target="_self" ; text-align:center">${service.name}</a> </h1>`;
  }
  $w(components.timezone).text = timezoneToShow(
    locale,
    timezone,
    businessTimezoneData,
  );
};

const getImageUrl = (image) => {
  const imageDimensions = { width: image.width, height: image.height };
  const targetWidth = imageDimensions.width || 0;
  const targetHeight = imageDimensions.height || 0;
  const imageUrl = imageSDK.getScaleToFitImageURL(
    image.url!,
    image.width!,
    image.height!,
    targetWidth,
    targetHeight,
  );
  return imageUrl
};
export const populateWidgetData = (
  $w,
  flowAPI,
  service,
  repeaterData,
  locale,
  timezone,
  biLogger,
) => {
  const t = flowAPI.translations.t;
  // console.log('UOU | populateWidgetData with service:', service);
  // console.log('UOU | populateWidgetData with repeaterData:', repeaterData);
  const serviceDurationAndPriceText = setDurationAndPrice(
    t,
    flowAPI.environment.isSSR,
    service,
    locale,
    repeaterData,
  );
  // console.log('serviceDurationAndPriceText:', serviceDurationAndPriceText);
  const textDirection = flowAPI.environment.isRTL ? 'rtl' : 'ltr';
  // console.log('html:', $w(components.serviceDurationAndPrice).html);

  $w(
    components.serviceDurationAndPrice,
  ).html = `<h1 dir=${textDirection} >${serviceDurationAndPriceText}</h1>`;

  // console.log('UOU | populateAvailabilityData repeaterData', repeaterData);

  if (!flowAPI.environment.isSSR) {
    if (repeaterData.length === 0) {
      // console.log('UOU | Setting empty state');
      $w(components.noFutureAvailabilityText).text = t(
        'app.widget.noServiceAvailability',
      );
      $w(components.availabilityStateBox).changeState('empty');
      biReportWidgetLoadedEmpty(biLogger, flowAPI.environment);
    } else {
      $w(components.seeMoreDatesAndTimes).text = t(
        'app.widget.seeMoreDatesAndTimes',
      );
      $w(components.seeMoreDatesAndTimes).link =
        service.urls.calendarPage.url.replace('-statics', '') +
        `?timezone=${repeaterData[0].slots[0].timezone}`;
      // console.log('UOU | Done setting service data');
      $w(components.mainRepeater).setRepeaterData(
        repeaterData,
        timezone,
        locale,
      );
      $w(components.availabilityStateBox).changeState('service');
    }
  } else {
    $w(components.availabilityStateBox).changeState('loader');
  }
};

const setDurationAndPrice = (t, isSSR, service, clientLocale, repeaterData) => {
  let durationText, textToDisplay;
  const priceText = `${configureServicePrice(t, isSSR, service, clientLocale)}`;
  // console.log('UOU | priceText:', priceText);
  if (service?.type.includes('APPOINTMENT')) {
    // console.log('UOU | Calculating service price for APPOINTMENT');
    durationText = `${calculateServiceDuration(t, service, [])}`;
    // console.log('UOU | durationText:', durationText);
    textToDisplay = `${durationText} • ${priceText}`;
  } else {
    // console.log(
    //   'UOU | Duration for non appointment services is configured by availability',
    // );
    if (repeaterData.length === 0) {
      textToDisplay = `${priceText}`;
    } else {
      durationText = `${calculateServiceDuration(t, service, repeaterData)}`;
      textToDisplay = `${durationText} • ${priceText}`;
    }
  }
  return textToDisplay;
};

export const calculateServiceDuration = (t, service, repeaterData): String => {
  let minutes;
  if (service?.type.includes('APPOINTMENT')) {
    // console.log('UOU | Calculating price for APPOINTMENT:', service);
    minutes = service?.schedule.availabilityConstraints.sessionDurations[0];
    // console.log('UOU | minutes:', minutes);
  } else {
    const milis =
      new Date(repeaterData[0].slots[0].endDate).getTime() -
      new Date(repeaterData[0].slots[0].startDate).getTime();
    const seconds = Math.floor(milis / 1000);
    minutes = Math.floor(seconds / 60);
  }
  const minutesRemainder = minutes % 60;
  const hours = Math.floor(minutes / 60);
  if (hours !== 0 && minutesRemainder === 0) {
    return t('app.widget.duration.units.hours', { hours });
  } else if (hours !== 0 && minutesRemainder !== 0) {
    return (
      t('app.widget.duration.units.hours', { hours }) +
      ' ' +
      t('app.widget.duration.units.minutes', { minutes: minutesRemainder })
    );
  } else {
    return t('app.widget.duration.units.minutes', {
      minutes: minutesRemainder,
    });
  }
};
export const configureServicePrice = (t, isSSR, service, locale): String => {
  let servicePrice, formattedPrice;
  switch (service.payment.rateType) {
    case 'FIXED':
      // servicePrice = new Intl.NumberFormat(isSSR ? DEFAULT_LOCALE : locale, { minimumFractionDigits: 0, style: 'currency', currency: service.payment.fixed.price.currency }).format(service.payment.fixed.price.value);
      servicePrice = PriceUtils.getFormattedCurrency({
        price: service.payment.fixed.price.value,
        currency: service.payment.fixed.price.currency,
        locale,
      });
      servicePrice =
        service.payment.pricingPlanIds.length === 0
          ? servicePrice
          : servicePrice + ` / ${t('app.widget.price.pricingPlan')}`;
      break;
    case 'NO_FEE':
      servicePrice =
        service.payment.pricingPlanIds.length === 0
          ? t('app.widget.price.free')
          : t('app.widget.price.pricingPlan');
      break;
    case 'VARIED':
      formattedPrice = new Intl.NumberFormat(isSSR ? DEFAULT_LOCALE : locale, {
        minimumFractionDigits: 0,
        style: 'currency',
        currency: service.payment.varied.minPrice.currency,
      }).format(service.payment.varied.minPrice.value);
      servicePrice = t('app.widget.price.from-price.text', {
        price: formattedPrice,
      });
      break;
    case 'CUSTOM':
      servicePrice = service.payment.custom.description;
      break;
  }
  // console.log('UOU | Service price:', servicePrice);
  return servicePrice;
};

export const timezoneToShow = (
  locale,
  selectedTimezone,
  businessLocaleData,
) => {
  let stringToreturn;
  const longTimezone = removeDateFromString(
    new Date().toLocaleDateString(locale, {
      timeZone: selectedTimezone,
      timeZoneName: 'long',
    }),
  );
  // console.log('UOU | longTimezone', longTimezone);

  const shortTimezone = removeDateFromString(
    new Date().toLocaleDateString(locale, {
      timeZone: selectedTimezone,
      timeZoneName: 'short',
    }),
  );
  // console.log('UOU | shortTimezone', shortTimezone);

  if (businessLocaleData.userTimezonePreference === 'business') {
    stringToreturn = `${longTimezone} (${shortTimezone})`;
  } else {
    const timeOffset = (new Date().getTimezoneOffset() * -1) / 60;
    // console.log(timeOffset);
    if (timeOffset > 0) {
      stringToreturn = `${longTimezone} (GMT +${timeOffset})`;
    } else if (timeOffset < 0) {
      stringToreturn = `${longTimezone} (GMT -${timeOffset})`;
    } else {
      stringToreturn = `${longTimezone} (GMT -${timeOffset})`;
    }
  }
  // console.log('UOU | timezone:', stringToreturn);
  return stringToreturn;
};

function removeDateFromString(string) {
  const words = string.trim().split(' ');
  words.shift();
  return words.join(' ');
}

export const queryFirstService = async (flowAPI) => {
  let responseServices = [];
  try {
    const response = await flowAPI.httpClient.post(
      `/_api/bookings/v2/services/query`,
      {
        includeDeleted: false,
        query: {
          filter: {
            hidden: {
              $ne: true,
            },
            type: {
              $in: ['APPOINTMENT', 'CLASS'],
            },
          },
          sort: [
            {
              fieldName: 'name',
              order: 'ASC',
            },
          ],
          paging: { offset: 0, limit: 1 },
        },
      },
    );
    responseServices = await response.data.services;
  } catch (e) {
    console.error('Catalog data error: ', e);
  }
  return responseServices;
};

export const getServiceV2ById = async (flowAPI, serviceId) => {
  let responseService;
  try {
    const response = await flowAPI.httpClient.get(
      `/_api/bookings/v2/services/${serviceId}`,
    );
    responseService = await response.data.service;
  } catch (e) {
    console.error('Catalog data error: ', e);
  }
  // console.log('UOU | getServiceV2ById responseService:', responseService);
  return responseService;
};

export const getTimezoneFromBusinessData = async (authorization) => {
  const servicesCatalogServer = ServicesCatalogServer(
    `${CATALOG_READER_BASE_URL}`,
  );
  const servicesCatalogService = servicesCatalogServer.BusinessCatalog();
  const getBusinessRequest: GetBusinessRequest = {
    suppressNotFoundError: false,
  };

  const business = await servicesCatalogService({
    Authorization: authorization,
  }).get(getBusinessRequest);
  // console.log('UOU | business:', business);
  const userTimezonePreference =
    business?.businessProperties?.customProperties?.find(
      (element) => element.propertyName === 'defaultTimezone',
    )?.value;
  const businessTimezone = business.info?.timeZone;
  return {
    businessTimezone,
    clientTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    userTimezonePreference: userTimezonePreference
      ? userTimezonePreference
      : 'business',
  };
};

export const calculateNumberOfSlotsFromMainRepeaterData = (
  mainRepeaterArray: any[],
) => {
  const arrayOfSlotTimes: string[] = [];
  if (mainRepeaterArray.length !== 0) {
    mainRepeaterArray.forEach((day) => {
      const daySlots = day.slots;
      // console.log('UOU | daySlots:', daySlots);
      daySlots.forEach((slotInDay) => {
        if (!arrayOfSlotTimes.includes(slotInDay.startDate)) {
          arrayOfSlotTimes.push(slotInDay.startDate);
        }
      });
    });
  }
  // console.log('UOU | Slots for BI:', arrayOfSlotTimes.length);
  return arrayOfSlotTimes.length;
};

export const translateWidgetElements = ($w, t) => {
  $w(components.noServicesText).text = t('app.widget.noServices');
  $w(components.vcBadge).setTranslation(t('app.widget.availableOnline'));
  if ($w(components.bookYourNextSession).text === 'Book your next session') {
    $w(components.bookYourNextSession).text = t('app.widget.bookNextSession');
  }
  $w(components.seeMoreDatesAndTimes).label = t(
    'app.widget.seeMoreDatesAndTimes',
  );
  $w(components.noFutureAvailabilityText).text = t(
    'app.widget.noServiceAvailability',
  );
};
